<template>
  <div class="idPoa">
    <div class="card bg-dark-gray" v-if="showIdentityProofAlert || idPoaPending">
      <div class="header bg-mirage-gray">
        <span>{{ $t(`idPoa.${idPoaState}.header`) }}</span>
        <i class="el-icon-warning-outline text-magenta" v-if="showIcon"></i>
      </div>
      <div class="content">
        <div class="mb-4">{{ $t(`idPoa.${this.idPoaState}.content`) }}</div>
        <div class="btnArea">
          <el-button
            class="el-button bg-magenta"
            v-if="showUploadButton"
            @click="pendingActionResponse"
            data-testid="confirm"
          >
            {{ uploadButtonText }}
            <em class="icon el-icon-arrow-right"></em>
          </el-button>
          <el-button
            class="el-button bg-light-green"
            @click="$router.push({ path: 'depositFunds' })"
            data-testid="depositFunds"
          >
            {{ $t('idPoa.depositNow') }}
            <em class="icon el-icon-arrow-right"></em>
          </el-button>
        </div>
      </div>
    </div>

    <UploadIdOrAddress
      :visible.sync="uploadIdOrAddress.visible"
      :idPendingReason="idPendingReason"
      :idCustomPendingReason="idCustomPendingReason"
      :poaPendingReason="poaPendingReason"
      :poaCustomPendingReason="poaCustomPendingReason"
      :idRecode="idRecode"
      :poaRecode="poaRecode"
    ></UploadIdOrAddress>
  </div>
</template>

<script>
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress';
import idPoaMixin from '@/mixins/idPoa';

const idPoaTemplate = {
  IdPoaNoPass: 'IdPoaNoPass',
  onlyIdPass: 'onlyIdPass',
  onlyPoaPass: 'onlyPoaPass',
  bothIdPoaPass: 'bothIdPoaPass'
};

export default {
  name: 'IdPoa',
  components: { UploadIdOrAddress },
  mixins: [idPoaMixin],
  mounted() {
    this.refreshIdPoa();
  },
  data() {
    return {
      uploadIdOrAddress: {
        visible: false
      }
    };
  },
  computed: {
    idPoaState() {
      const requireID = this.requireIDProof;
      const requirePoa = this.requireAddressProof;

      if (requireID && requirePoa) return idPoaTemplate.IdPoaNoPass; // No ID/POA submitted
      if (!requireID && requirePoa) return idPoaTemplate.onlyIdPass; // Only ID submitted
      if (requireID && !requirePoa) return idPoaTemplate.onlyPoaPass; // Only POA submitted
      if (!requireID && !requirePoa) return idPoaTemplate.bothIdPoaPass; // Both ID/POA submitted
    },
    showUploadButton() {
      switch (this.idPoaState) {
        case idPoaTemplate.IdPoaNoPass:
          return true;
        case idPoaTemplate.onlyIdPass:
          return true;
        case idPoaTemplate.onlyPoaPass:
          return true;
        case idPoaTemplate.bothIdPoaPass:
          return false;
      }
    },
    showIcon() {
      switch (this.idPoaState) {
        case idPoaTemplate.IdPoaNoPass:
          return true;
        case idPoaTemplate.onlyIdPass:
          return false;
        case idPoaTemplate.onlyPoaPass:
          return true;
        case idPoaTemplate.bothIdPoaPass:
          return false;
      }
    },
    uploadButtonText() {
      switch (this.idPoaState) {
        case idPoaTemplate.IdPoaNoPass:
          return this.$t('idPoa.uploadID');
        case idPoaTemplate.onlyIdPass:
          return this.$t('idPoa.uploadPoa');
        case idPoaTemplate.onlyPoaPass:
          return this.$t('idPoa.uploadID');
        case idPoaTemplate.bothIdPoaPass:
          return '';
      }
    }
  },
  methods: {
    async pendingActionResponse() {
      await this.refreshIdPoa();
      if (this.showIdentityProofAlert) {
        this.uploadIdOrAddress.visible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/idPoa/index.scss';
</style>
